import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaShieldAlt, FaRocket } from "react-icons/fa";

const Solutions = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      {/* <section className="bg-gradient-to-r from-purple-800 to-orange-500 text-white py-24"> */}
      <section className="bg-transparent text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1
            className="text-5xl md:text-7xl font-bold mb-6 text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Our Solutions
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl text-center max-w-3xl mx-auto mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Empowering businesses with cutting-edge AI technologies to stay
            ahead in a rapidly evolving digital landscape.
          </motion.p>
        </div>
      </section>

      {/* Core Focus Areas */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-24 text-center text-gray-800">
            Our Focus Areas
          </h2>

          {/* Cybersecurity Framework Section */}
          <div className="flex flex-col space-y-8 mb-40">
            <div className="flex flex-col items-center mb-6">
              <FaShieldAlt className="text-5xl text-[#ff6b00] mb-4" />
              <h3 className="text-4xl font-semibold text-[#ff6b00] text-center">
                Cybersecurity Framework
              </h3>
            </div>
            <div className="flex flex-col lg:flex-row items-start space-y-8 lg:space-y-0 lg:space-x-8">
              <motion.div
                className="w-full lg:w-1/2"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <p className="text-lg text-gray-600 mb-8">
                  FenxLabs' AI-powered cybersecurity framework is a
                  ready-to-deploy solution designed to enhance an organisation's
                  security posture with minimal setup time. This framework
                  offers comprehensive threat detection, utilising machine
                  learning to identify patterns in network traffic, detect
                  anomalies, and predict potential threats before they
                  materialise. The system supports real-time monitoring and
                  alerting, allowing security teams to respond swiftly to
                  incidents.
                </p>
                <p className="text-lg text-gray-600 mb-8">
                  Our cybersecurity solution goes beyond threat detection; it
                  includes robust incident response capabilities, enabling
                  automated or guided responses based on predefined protocols.
                  By leveraging predictive analytics, the framework can identify
                  high-risk behaviours and potential vulnerabilities, empowering
                  organisations to strengthen their defences proactively.
                  Additionally, the framework supports integration with other
                  security tools, providing a cohesive view of security across
                  the entire IT ecosystem. Customizable and scalable, this
                  framework is ideal for organisations seeking to enhance their
                  cybersecurity efforts through the power of AI.
                </p>
                <div>
                  <Link
                    to="/products/cybersecurity"
                    className="inline-block px-8 py-3 bg-[#ff6b00] text-white rounded-full hover:bg-orange-700 transition-colors text-lg font-semibold shadow-md hover:shadow-lg"
                  >
                    Learn More
                  </Link>
                </div>
              </motion.div>
              <motion.div
                className="w-full lg:w-1/2 flex items-center justify-center"
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                style={{ height: "50%", minHeight: "100px" }}
              >
                <div className="w-full h-0 pb-[86.25%] relative">
                  <iframe
                    src="https://www.youtube.com/embed/G4lyApS7yEQ?si=EAZQfvolu_KXuCPr&rel=0"
                    title="Cybersecurity Demo"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                  ></iframe>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      {/* Call to Action */}
      <section className="py-20 bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ready to Innovate with AI?
          </motion.h2>
          <motion.p
            className="text-xl mb-12 max-w-3xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Discover how FenxLabs' cutting-edge AI research and engineering
            services can drive your business forward. Let's collaborate to turn
            innovative ideas into reality.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <Link
              to="/contact"
              className="px-8 py-4 bg-white text-[#ff6b00] rounded-full hover:bg-gray-100 transition-colors text-lg font-semibold shadow-lg hover:shadow-xl inline-flex items-center"
            >
              <FaRocket className="mr-2" /> Contact Us
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
