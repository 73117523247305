import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./Navigation";
import BackGroundOverlay from "./Background";
import Home from "../../pages/marketing/Home";
import Login from "../../pages/shared/authentication/Login";
import Register from "../../pages/marketing/Register";
import BespokeSolutions from "../../pages/marketing/BespokeSolutions";
import HorizonScanning from "../../pages/marketing/HorizonScanning";
import MicroConsultancy from "../../pages/marketing/MicroConsultancy";
import About from "../../pages/marketing/About";
import Solutions from "../../pages/marketing/Solutions";
import Platform from "../../pages/marketing/Platform";
import Contact from "../../pages/marketing/Contact";
import Articles from "../../pages/marketing/Articles";
import Article from "../../pages/marketing/Article";
import PromptEngineer from "../../pages/marketing/PromptEngineer";
import ContentGenerator from "../../pages/marketing/ContentGenerator";
import Chat from "../../pages/marketing/Chat";
import Services from "../../pages/marketing/Services";
import Footer from "./Footer";
import ScrollToTop from "../shared/ScrollToTop";
import PrivacyTermsPage from "../../pages/marketing/Privacy";
import TermsPage from "../../pages/marketing/Terms";
import CheckBackLater from "../../pages/marketing/CheckBackLater";
import CookieBanner from "./CookieBanner";

function MarketingAppContent() {
  return (
    <>
      <ScrollToTop />
      <BackGroundOverlay />
      <Navigation />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login initialTab={0} />} />
        <Route path="/request-access" element={<Login initialTab={1} />} />
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route
          path="/products/bespoke-solutions"
          element={<BespokeSolutions />}
        />
        <Route
          path="/products/horizon-scanning"
          element={<HorizonScanning />}
        /> */}
        {/* <Route
          path="/products/micro-consultancy"
          element={<MicroConsultancy />}
        /> */}
        <Route path="/about" element={<About />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/article" element={<Article />} />
        <Route path="/services" element={<Services />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/privacy" element={<PrivacyTermsPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/internal-tools/chat" element={<Chat />} />
        <Route
          path="/internal-tools/prompt-engineer"
          element={<PromptEngineer />}
        />
        <Route
          path="/internal-tools/content-generator"
          element={<ContentGenerator />}
        />
        <Route path="/check-back-later" element={<CheckBackLater />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
}

export default MarketingAppContent;
