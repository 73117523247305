import React from "react";
import { Routes, Route } from "react-router-dom";
import UserOverviewPanel from "./overview_panel/userOverviewPanel";
// import UserDashboardNavigationDrawer from "./navigation_drawer/UserDashboardNavigationDrawer";
import UserDashboardHome from "../../pages/user_dashboard/UserDashboardHome";
import HorizonScanner from "../../pages/user_dashboard/HorizonScanner/HorizonScanner";
import Profile from "../../pages/user_dashboard/Profile";
import Admin from "../../pages/user_dashboard/Admin";
import CyberAIDashboard from "../../pages/user_dashboard/CyberSecurity/CyberAIDashboard";
import DummyServicePage from "../../pages/user_dashboard/DummyServiceFrontend/DummyServicePage";
import Settings from "../../pages/user_dashboard/Settings";
import CreditBalancePage from "../../pages/user_dashboard/FenxCredits/CreditsDashboard";
import CreditPurchaseSuccess from "../../pages/user_dashboard/FenxCredits/CreditPurchaseSuccess";
import CreditPurchaseCancel from "../../pages/user_dashboard/FenxCredits/CreditPurchaseCancel";
import HorizonScannerQuestionnaire from "../../pages/user_dashboard/HorizonScanner/HorizonScannerQuestionnaire";
import Access from "../../pages/user_dashboard/Access";

function UserDashboardAppContent() {
  return (
    <>
      {/* <UserDashboardNavigationDrawer /> */}
      <Routes>
        <Route path="/" element={<UserDashboardHome />} />
        <Route path="/horizonscanner" element={<HorizonScanner />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/access" element={<Access />} />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/cyber-security-dashboard"
          element={<CyberAIDashboard />}
        />
        <Route path="/dummy-service" element={<DummyServicePage />} />
        <Route path="/credits/success" element={<CreditPurchaseSuccess />} />
        <Route path="/credits/cancel" element={<CreditPurchaseCancel />} />
        <Route path="/credits" element={<CreditBalancePage />} />
        <Route
          path="/horizon-scanner-feedback"
          element={<HorizonScannerQuestionnaire />}
        />
      </Routes>
      <UserOverviewPanel />
    </>
  );
}

export default UserDashboardAppContent;
