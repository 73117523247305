import React, { useState } from "react";
import { auth, db } from "../../../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Paper,
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  Checkbox,
  FormControlLabel,
  Link,
  styled,
} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F37135",
  "&:hover": {
    backgroundColor: "#d85f2d",
  },
}));

const Login = ({ initialTab = 0 }) => {
  const [tab, setTab] = useState(initialTab);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Login state
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // Register state
  const [registerData, setRegisterData] = useState({
    fullName: "",
    email: "",
    password: "",
    country: "",
    company: "",
    vat: "",
    address: "",
    phone: "",
  });

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setError(null);
    setSuccessMessage("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      await signInWithEmailAndPassword(
        auth,
        loginData.email,
        loginData.password
      );

      // Get the redirect path from location state
      const fromPath = location.state?.from?.pathname;

      // If there's a redirect path and it contains '/dashboard', use it
      // Otherwise, redirect to /dashboard
      if (fromPath && fromPath.includes("/dashboard")) {
        navigate(fromPath, { replace: true });
      } else {
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    if (!termsAccepted || !privacyAccepted) {
      setError(
        "You must accept the Terms & Conditions and Privacy Policy to continue."
      );
      setIsLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerData.email,
        registerData.password
      );

      await updateProfile(userCredential.user, {
        displayName: registerData.fullName,
      });

      await setDoc(doc(db, "users", userCredential.user.uid), {
        fullName: registerData.fullName,
        email: registerData.email,
        country: registerData.country,
        company: registerData.company,
        vat: registerData.vat,
        address: registerData.address,
        phone: registerData.phone,
        role: "User",
        termsAccepted: true,
        privacyAccepted: true,
      });

      // Clear registration form
      setRegisterData({
        fullName: "",
        email: "",
        password: "",
        country: "",
        company: "",
        vat: "",
        address: "",
        phone: "",
      });

      // Set success message and switch to login tab
      setSuccessMessage(
        "Registration successful! Please login with your credentials."
      );
      setTab(0);

      // Pre-fill login email with registered email
      setLoginData({
        ...loginData,
        email: userCredential.user.email,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "50vh",
        background: "white",
        py: 4,
      }}
    >
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={6}>
          <Typography component="h1" variant="h4" gutterBottom>
            Welcome to FenxLabs
          </Typography>

          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ width: "100%", mb: 3 }}
          >
            <Tab label="Login" />
            <Tab label="Request Access" />
          </Tabs>

          {error && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              {error}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
              {successMessage}
            </Alert>
          )}

          {tab === 0 && (
            <Box component="form" onSubmit={handleLogin} sx={{ width: "100%" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                type="email"
                value={loginData.email}
                onChange={(e) =>
                  setLoginData({ ...loginData, email: e.target.value })
                }
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading ? "Signing in..." : "Sign In"}
              </StyledButton>
            </Box>
          )}

          {tab === 1 && (
            <Box
              component="form"
              onSubmit={handleRegister}
              sx={{ width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Full Name"
                value={registerData.fullName}
                onChange={(e) =>
                  setRegisterData({ ...registerData, fullName: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                value={registerData.email}
                onChange={(e) =>
                  setRegisterData({ ...registerData, email: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={registerData.password}
                onChange={(e) =>
                  setRegisterData({ ...registerData, password: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Country"
                value={registerData.country}
                onChange={(e) =>
                  setRegisterData({ ...registerData, country: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Company"
                value={registerData.company}
                onChange={(e) =>
                  setRegisterData({ ...registerData, company: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="VAT"
                value={registerData.vat}
                onChange={(e) =>
                  setRegisterData({ ...registerData, vat: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Address"
                value={registerData.address}
                onChange={(e) =>
                  setRegisterData({ ...registerData, address: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Phone Number"
                value={registerData.phone}
                onChange={(e) =>
                  setRegisterData({ ...registerData, phone: e.target.value })
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    name="terms"
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the{" "}
                    <Link
                      href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e131b7b3-5f50-4e04-b5f0-f2f1aea657d7"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={privacyAccepted}
                    onChange={(e) => setPrivacyAccepted(e.target.checked)}
                    name="privacy"
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the{" "}
                    <Link
                      href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b6cab223-31f1-4758-ae56-7d148e3bdbb4"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                }
              />

              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading
                  ? "Creating account..."
                  : "Create Account & Request Access"}
              </StyledButton>
            </Box>
          )}
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default Login;
