import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  // FaShieldAlt,
  FaSearchPlus,
  FaRocket,
  // FaChevronDown,
  // FaChevronUp,
} from "react-icons/fa";

const Platform = () => {
  const horizonScannerSections = [
    {
      title: "Overview",
      content:
        "The Horizon Scanning tool is designed for organisations seeking to maintain a competitive edge by staying informed about emerging trends, technologies, and industry shifts. Powered by agentic AI, this tool continuously monitors a vast array of data sources, filtering and analysing information to identify impactful trends. The Horizon Scanner provides actionable insights, helping organisations anticipate changes in their industry, mitigate risks, and seize new opportunities.",
    },
    {
      title: "Capabilities",
      content:
        "With a sophisticated AI engine, the Horizon Scanning tool categorises and prioritises trends, offering detailed insights on each. It not only highlights upcoming challenges but also provides recommendations for strategic responses. This tool is particularly valuable for industries such as finance and technology, where rapid shifts can present both risks and opportunities. Its ability to generate customised reports tailored to a client's specific industry and focus areas sets it apart as a critical asset for strategic planning.",
    },
    // {
    //   title: "User-Friendly Interface",
    //   content:
    //     "The Horizon Scanning tool's interface is designed for ease of use, featuring customizable search parameters that allow users to focus on the insights most relevant to their needs. The interface also includes interactive dashboards, trend visualisation tools, and automated report generation, ensuring that decision-makers have access to timely, actionable data. Users can set up alerts for specific trends or topics, making it easy to stay informed about critical changes as they occur.",
    // },
    {
      title: "Delivery Options",
      content:
        "The Horizon Scanning tool's interface is designed for ease of use, featuring customizable search parameters that allow users to focus on the insights most relevant to their needs. The interface also includes interactive dashboards, trend visualisation tools, and automated report generation, ensuring that decision-makers have access to timely, actionable data. Users can set up alerts for specific trends or topics, making it easy to stay informed about critical changes as they occur.",
    },
    {
      title: "Delivery Options",
      content:
        "The Horizon Scanning tool is offered through three flexible deployment options:",
      bulletPoints: [
        "As part of the Fenx Platform with pay-per-report pricing",
        "As a Custom Software as a Service (CSaaS)",
        "As a dedicated On-Premise installation",
      ],
      footer:
        "Each option provides full access to the tool's advanced scanning and analysis capabilities.",
    },
    {
      title: "Future Tools",
      content:
        "FenxLabs is dedicated to expanding our platform to meet the evolving needs of our clients. Future additions will include tools like a web scraping assistant, which can analyse and summarise online content for research purposes, and an AI-powered chatbot that can interact with internal databases to answer employee queries. These tools will enhance the utility of our platform, making it a versatile resource for both strategic planning and operational efficiency. We are also exploring additional features, such as translation services and automated data tagging, which will further expand the platform's capabilities.",
    },
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section - Kept Exactly as Original */}
      <section className="bg-transparent text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1
            className="text-5xl md:text-7xl font-bold mb-6 text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Our Platform
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl text-center max-w-3xl mx-auto mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Empowering you with cutting-edge AI technologies to stay ahead in a
            rapidly evolving digital landscape.
          </motion.p>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          {/* Title */}
          <div className="text-center mb-16">
            <FaSearchPlus className="text-5xl text-[#ff6b00] mb-4 mx-auto" />
            <h3 className="text-4xl font-semibold text-[#ff6b00]">
              Horizon Scanning Tool
            </h3>
          </div>

          {/* Video and Overview Section */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 items-start mb-24">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="lg:col-span-2 bg-black rounded-3xl overflow-hidden shadow-2xl aspect-video"
            >
              <iframe
                src="https://www.youtube.com/embed/NpEaa2P7qZI"
                title="Horizon Scanning Tool Demo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="bg-gray-50 rounded-3xl p-8 shadow-xl"
            >
              <h4 className="text-2xl font-semibold mb-6 text-gray-900">
                {horizonScannerSections[0].title}
              </h4>
              <p className="text-gray-600 leading-relaxed">
                {horizonScannerSections[0].content}
              </p>
            </motion.div>
          </div>

          {/* Feature Sections */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {horizonScannerSections.slice(1).map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-gray-50 rounded-3xl p-8 shadow-xl hover:shadow-2xl transition-shadow duration-300"
              >
                <h4 className="text-2xl font-semibold mb-6 text-[#ff6b00] text-center">
                  {section.title}
                </h4>
                {section.title === "Delivery Options" ? (
                  <div className="text-gray-600 leading-relaxed">
                    <p className="mb-4">{section.content}</p>
                    {section.bulletPoints && (
                      <ul className="list-disc pl-6 mb-4 space-y-2">
                        {section.bulletPoints.map((point, i) => (
                          <li key={i}>{point}</li>
                        ))}
                      </ul>
                    )}

                    {/* <ul className="list-disc pl-6 mb-4 space-y-2">
                      {section.bulletPoints.map((point, i) => (
                        <li key={i}>{point}</li>
                      ))}
                    </ul> */}
                    <p>{section.footer}</p>
                  </div>
                ) : (
                  <p className="text-gray-600 leading-relaxed">
                    {section.content}
                  </p>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ready to Innovate with AI?
          </motion.h2>
          <motion.p
            className="text-xl mb-12 max-w-3xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Discover how FenxLabs' cutting-edge AI research and engineering
            services can drive your business forward. Let's collaborate to turn
            innovative ideas into reality.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <Link
              to="/contact"
              className="px-8 py-4 bg-white text-[#ff6b00] rounded-full hover:bg-gray-100 transition-colors text-lg font-semibold shadow-lg hover:shadow-xl inline-flex items-center"
            >
              <FaRocket className="mr-2" /> Contact Us
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Platform;
