import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { makeAuthenticatedRequest } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";

const Access = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await makeAuthenticatedRequest(
        "/admin/get-user-database",
        {},
        user
      );
      console.log("Response data:", response.data);
      // Assuming response.data contains the user list
      const userList = Array.isArray(response.data.user_list)
        ? response.data.user_list
        : [];
      setUsers(userList);
    } catch (error) {
      alert("error fetching users:", error);
      console.error("Error fetching users:", error);
    }
  };

  const handleAccessToggle = async (userId, hasAccess) => {
    try {
      const endpoint = hasAccess
        ? "/admin/revoke-access"
        : "/admin/grant-access";

      const response = await makeAuthenticatedRequest(
        endpoint,
        { userId },
        user
      );

      // Extract the actual data from the response
      const { data } = response;

      console.log("Response from server:", data); // Log the extracted data

      if (data?.status === "success") {
        // Optimistically update the user in the list
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.firebase_id === userId ? { ...u, has_access: !hasAccess } : u
          )
        );
      } else {
        throw new Error(data?.message || "Failed to update access.");
      }
    } catch (error) {
      console.error("Error updating access:", error);
      alert("An error occurred while updating access. Please try again.");
    }
  };

  console.log("Users", users);

  const filteredUsers = users.filter((user) =>
    Object.values(user)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  console.log("Filtered Users:", filteredUsers); // Add this line

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Access Request Dashboard
      </Typography>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        <Tab label="Access Requests" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {selectedTab === 0 && (
          <>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Search users"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
              />
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Credits</TableCell>
                    <TableCell>Access</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.name}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.created_at}</TableCell>
                      <TableCell>{user.tokens_available}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color={user.has_access ? "error" : "primary"}
                          onClick={() =>
                            handleAccessToggle(
                              user.firebase_id,
                              user.has_access
                            )
                          }
                        >
                          {user.has_access ? "Revoke Access" : "Grant Access"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Access;
