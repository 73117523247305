import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

function Admin() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    fullName: "",
    email: "",
    password: "",
    role: "User",
    country: "",
    company: "",
    vat: "",
    address: "",
    phone: "",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const usersCollection = collection(db, "users");
    const userSnapshot = await getDocs(usersCollection);
    const userList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(userList);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    Object.values(user).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleUpdateUser = async () => {
    if (selectedUser) {
      const userRef = doc(db, "users", selectedUser.id);
      await updateDoc(userRef, selectedUser);
      fetchUsers();
      handleCloseDialog();
    }
  };

  const handleDeleteUser = async (userId) => {
    await deleteDoc(doc(db, "users", userId));
    fetchUsers();
  };

  const handleCreateUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );
      await setDoc(doc(db, "users", userCredential.user.uid), {
        fullName: newUser.fullName,
        email: newUser.email,
        role: newUser.role,
        country: newUser.country,
        company: newUser.company,
        vat: newUser.vat,
        address: newUser.address,
        phone: newUser.phone,
      });
      fetchUsers();
      setNewUser({
        fullName: "",
        email: "",
        password: "",
        role: "User",
        country: "",
        company: "",
        vat: newUser.vat,
        address: newUser.address,
        phone: "",
      });
      handleCloseDialog();
    } catch (error) {
      console.error("Error creating new user:", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Dashboard
      </Typography>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        <Tab label="User Management" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {selectedTab === 0 && (
          <>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Search users"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Create New User
            </Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.country}</TableCell>
                      <TableCell>{user.company}</TableCell>
                      <TableCell>{user.vat || "-"}</TableCell>
                      <TableCell>{user.address || "-"}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleEditUser(user)}>
                          Edit
                        </Button>
                        <Button onClick={() => handleDeleteUser(user.id)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedUser ? "Edit User" : "Create New User"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            fullWidth
            value={selectedUser ? selectedUser.fullName : newUser.fullName}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, fullName: e.target.value })
                : setNewUser({ ...newUser, fullName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={selectedUser ? selectedUser.email : newUser.email}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, email: e.target.value })
                : setNewUser({ ...newUser, email: e.target.value })
            }
          />
          {!selectedUser && (
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
            />
          )}
          <TextField
            margin="dense"
            label="Country"
            fullWidth
            value={selectedUser ? selectedUser.country : newUser.country}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, country: e.target.value })
                : setNewUser({ ...newUser, country: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Company"
            fullWidth
            value={selectedUser ? selectedUser.company : newUser.company}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, company: e.target.value })
                : setNewUser({ ...newUser, company: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="VAT"
            fullWidth
            value={selectedUser ? selectedUser.vat : newUser.vat}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, vat: e.target.value })
                : setNewUser({ ...newUser, vat: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Address"
            fullWidth
            value={selectedUser ? selectedUser.address : newUser.address}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, address: e.target.value })
                : setNewUser({ ...newUser, address: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Phone"
            fullWidth
            value={selectedUser ? selectedUser.phone : newUser.phone}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, phone: e.target.value })
                : setNewUser({ ...newUser, phone: e.target.value })
            }
          />
          <Select
            fullWidth
            value={selectedUser ? selectedUser.role : newUser.role}
            onChange={(e) =>
              selectedUser
                ? setSelectedUser({ ...selectedUser, role: e.target.value })
                : setNewUser({ ...newUser, role: e.target.value })
            }
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={selectedUser ? handleUpdateUser : handleCreateUser}>
            {selectedUser ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Admin;
