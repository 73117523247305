// utils/api.js

import axios from "axios";

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

/**
 * Utility function to make authenticated requests to the Flask backend.
 *
 * @param {string} endpoint - The API endpoint (e.g., "/credit-operations/create-checkout-session").
 * @param {object} data - The request payload.
 * @param {object} user - The user object from Firebase Auth.
 * @param {string} [responseType="json"] - The expected response type (e.g., "json", "blob").
 * @returns {Promise<object>} - The full Axios response object.
 * @throws {Error} - If the request fails.
 */
export const makeAuthenticatedRequest = async (
  endpoint,
  data,
  user,
  responseType = "json"
) => {
  try {
    const id_token = await user.getIdToken();
    const response = await axios.post(
      `${FLASK_API_BASE_URL}${endpoint}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${id_token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        responseType, // Set the response type dynamically
      }
    );
    return response; // Return the full Axios response object
  } catch (error) {
    console.error("API Request Error:", error);
    throw new Error("Failed to make authenticated request.");
  }
};
